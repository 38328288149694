import clsx from 'clsx'

interface Props {
  className?: string
}

export const AffiliateDisclosure = ({ className }: Props) => (
  <p
    className={clsx(
      'content-disclosure bg-off-white rounded-lg p-4',
      className,
    )}
  >
    ATM.com&reg; may receive affiliate compensation for purchases made from
    affiliate sellers when you click on their links on our site.
  </p>
)

export const InstantCashDisclosure = ({ className }: Props) => (
  <p className={clsx('content-disclosure text-dolphin-900', className)}>
    *Eligibility is based on review and approval. Not all applicants will be
    eligible for ATM Instant Cash™.{' '}
    <span className="whitespace-pre">Transfer fees may apply.</span>
  </p>
)
